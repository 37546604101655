exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-en-js": () => import("./../../../src/pages/about.en.js" /* webpackChunkName: "component---src-pages-about-en-js" */),
  "component---src-pages-about-ja-js": () => import("./../../../src/pages/about.ja.js" /* webpackChunkName: "component---src-pages-about-ja-js" */),
  "component---src-pages-activity-en-js": () => import("./../../../src/pages/activity.en.js" /* webpackChunkName: "component---src-pages-activity-en-js" */),
  "component---src-pages-activity-ja-js": () => import("./../../../src/pages/activity.ja.js" /* webpackChunkName: "component---src-pages-activity-ja-js" */),
  "component---src-pages-index-en-js": () => import("./../../../src/pages/index.en.js" /* webpackChunkName: "component---src-pages-index-en-js" */),
  "component---src-pages-index-ja-js": () => import("./../../../src/pages/index.ja.js" /* webpackChunkName: "component---src-pages-index-ja-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-lecture-en-js": () => import("./../../../src/pages/lecture.en.js" /* webpackChunkName: "component---src-pages-lecture-en-js" */),
  "component---src-pages-lecture-ja-js": () => import("./../../../src/pages/lecture.ja.js" /* webpackChunkName: "component---src-pages-lecture-ja-js" */),
  "component---src-pages-others-en-js": () => import("./../../../src/pages/others.en.js" /* webpackChunkName: "component---src-pages-others-en-js" */),
  "component---src-pages-others-ja-js": () => import("./../../../src/pages/others.ja.js" /* webpackChunkName: "component---src-pages-others-ja-js" */),
  "component---src-pages-publications-en-js": () => import("./../../../src/pages/publications.en.js" /* webpackChunkName: "component---src-pages-publications-en-js" */),
  "component---src-pages-publications-ja-js": () => import("./../../../src/pages/publications.ja.js" /* webpackChunkName: "component---src-pages-publications-ja-js" */)
}

